import React from 'react'
import Banner from '../Components/Banner'
import Card from '../Components/Card'
const Home = () => {
  return (
    <div>
        <Banner/>
        <Card/>
    </div>
  )
}

export default Home