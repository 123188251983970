import React from 'react'

const Footer = () => {
  return (
    <div className='container-fluid bg-red'>
        <div className='container'>
            <div className='row'>
                <p className='text-center p-2 mt-2 text-light'><b>All Copy Right ​KARAOKEDAYTON Design BY <a href='https://hnhtechsolutions.com/' target='blank' className='text-light'>HNH TECH SOLUTIONS</a></b></p>
            </div>
        </div>
    </div>
  )
}

export default Footer