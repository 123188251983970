import React from "react";
import Zoom from "react-reveal/Zoom";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
const Banner = () => {
    const navigate = useNavigate();
    const reDirect =()=>{
       navigate('/bookus')
    }
  return (
    <div className="container-fluid bg-img mt-100">
      <div className="container d-flex flex-column align-items-center justify-content-center">
        <div className="row">
          <div className="col-md-12 col-12 col-lg-12">
            <div className="row">
              <Zoom>
                <h1 data-aos="fade-left" className="text-center text-light h1">
                  ​KARAOKEDAYTON
                </h1>
                <h2 data-aos="fade-left" className="text-center text-light ">
                  We bring the sing to Dayton <br/>Over 25 years entertaining Dayton
                </h2>
              </Zoom>
            </div>

           
          </div>
        </div>
        <div className="row justify-content-center book-button">
            <button onClick={reDirect} className=" col-12 py-2 btnho-sh d-flex align-items-center justify-content-center px-5">
              BOOK US <BsArrowRightShort size={38} />
            </button>
            <h4 className="text-center text-light mt-4" >Call DJ Topher at 9378777477</h4>
            </div>
      </div>
    </div>
  );
};

export default Banner;
