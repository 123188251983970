import React from "react";
import two from "../images/two.webp";
import four from "../images/four.jpg";
import six from '../images/six.jpg'
import  seven from '../images/seven.jpg'
import Zoom from "react-reveal/Zoom";
const Card = () => {
  return (
    <div className="container-fluid bg-dark ">
      <div className="container p-5">
        <div className="row py-4">
          <div className="col-12 text-center text-light">
            <h6>We Bring the Sing to Dayton</h6>
            <h1 style={{ color: "red" }}>Karaoke - DJ - Trivia </h1>
            <p>and more!!</p>
          </div>
        </div>
        <div className="row text-light">
          <Zoom>
            <div className="col-md-6 my-3 col-lg-3 col-12 ">
              <div className="glowing-circle">
                <img
                  src={seven}
                  alt="img"
                  width={"100%"}
                  height={"300px"}
                  style={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="col-md-6  my-3 col-lg-3 col-12">
              <div className="glowing-circle">
                <img
                  src={two}
                  alt="img"
                  width={"100%"}
                  height={"300px"}
                  style={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="col-md-6 my-3  col-lg-3 col-12">
              <div className="glowing-circle">
                <img
                  src={six}
                  alt="img"
                  width={"100%"}
                  height={"300px"}
                  style={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="col-md-6  my-3 col-lg-3 col-12">
              <div className="glowing-circle">
                <img
                  src={four}
                  alt="img"
                  width={"100%"}
                  height={"300px"}
                  style={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          </Zoom>
        </div>
       
      </div>
    </div>
  );
};

export default Card;
