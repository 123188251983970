import React, { useState } from "react";
import {Link} from 'react-router-dom'
import Logo from '../images/logo.png'
const Header = () => {
  const [show,hide] = useState(true)
   setTimeout(myGreeting, 2000);
  function myGreeting() {
     hide(false)
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light  fixed-top bg-red">
      <div class="container">
        <a class="navbar-brand " href="/">
            <img src={Logo} alt="logo" width={'90px'}/>
        </a>
        <button
          class="navbar-toggler white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon white"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link text-light me-0 me-md-5" style={{fontSize:'22px'}} aria-current="page" href="/">
                Home
              </a>
            </li>
            <li class="nav-item">
              <Link class="nav-link text-light" to="/bookus" style={{fontSize:'22px'}}>
                Book us
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {
        show ? <div className="logoss"/> : null
      }
      
    </nav>
  );
};

export default Header;
