import React, { useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { MdMarkEmailRead } from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
const From = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    date: "",
    email: "",
    additionalComments: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.phoneNumber === "" ||
      formData.date === "" ||
      formData.email === "" ||
      formData.additionalComments === ""
    ) {
      toast.error("Please Fill All the Field");
    } else
      try {
        console.log(formData, "formData");
        const formDataToSend = new FormData();
        formDataToSend.append("first_name", formData.firstName);
        formDataToSend.append("last_name", formData.lastName);
        formDataToSend.append("phone_no", formData.phoneNumber);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("date", formData.date);
        formDataToSend.append(
          "message",
          formData.additionalComments
        );

        axios
          .post("https://assignmentapi.dev-nuh.xyz/api/sent/email", formDataToSend)
          .then((response) => {
            toast.success(response.data.message);
            console.log("Data sent successfully:", response.data);
            setFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              date: "",
              email: "",
              additionalComments: "",
            });
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      } catch (error) {}
  };
  return (
    <div className="container my-5">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <div className="col-md-12 col-lg-6 col-12">
          <div className="row">
            <div
              className=" col-12  d-flex flex-column align-items-center justify-content-center"
              style={{ height: "100px" }}
            >
              <h1 className="">Inquire</h1>
              <p> Indicates required field</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 col-lg-6 col-12">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  class="form-control"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  class="form-control"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  class="form-control"
                  value={formData.date}
                  onChange={handleChange}
                  placeholder="Date"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                class="form-control"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="row">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Venue/Additional Comments
              </label>
              <textarea
                name="additionalComments"
                value={formData.additionalComments}
                class="form-control"
                onChange={handleChange}
                placeholder="Venue/Additional Comments"
                rows="3"
              />
            </div>
          </div>
          <div className="row">
            <button
              className="btn bg-color text-light col-8 mx-auto"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-12 d-flex justify-content-center align-items-center my-3">
          <div className="row  mx-auto">
            <div>
              <h3>
                {" "}
                <AiOutlineUsergroupAdd className="mx-3" color="red" />
                DJ Chris Adams
              </h3>
              <h3>
                <BiPhoneCall className="mx-3" color="red" /> 937-877-7477
              </h3>
              <h3>
                <MdMarkEmailRead className="mx-3" color="red" />{" "}
                chris@karaokedayton.com
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default From;
